/* Table look changes start */

body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
    background-color: #fff !important;
    /* color: #333333; */
    /* border: 1px solid #c8c8c8; */
    padding: 0.571em 1em;
    text-align: center;
}



body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
    background-color: #ffffff;
    border: none;
    border-bottom: 0;
}

body .p-paginator {
    border: 1px solid #e5e5e5;
    background-color: #fff !important;
}

body .p-datatable .p-datatable-thead>tr>th {
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
}

body .p-datatable .p-datatable-tbody>tr>td {
    border: none;
}


/* Table look changes end */

body .pi {
    font-size: 1em;
}

.imgStyles {
    width: 30px;
    height: 30px;
    margin-top: 0px;
    border-radius: 50%;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: $button-bg-color;
    color: $button-text-color;
}

body .p-datatable .p-datatable-tbody>tr.p-highlight {
    background-color: #64677778;
    color: #ffffff;
}

body .p-datatable .p-datatable-tbody>tr:nth-child(even).p-highlight {
    background-color: #64677778;
    color: #ffffff;
}

body .p-datatable-loading-content {
    position: absolute;
    left: 50% !important;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
}

body .p-datatable .p-datatable-thead>tr>th {
    background-color: $table-header-bg-color;
    color: $table-header-text-color;
}